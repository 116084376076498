import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useContext, useState, useRef } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Landing from "./pages/Landing";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profile1 from "./components/Profiles/Profile1";
import Profileincom from "./pages/Profileincom";
import Profile3 from "./components/Profiles/Profile3";
import Profile4 from "./components/Profiles/Profile4";
import Profile5 from "./components/Profiles/Profile5";
import Educationreview from "./components/Educationreview";
import JobReview from "./components/JobReview";
import Profile6 from "./components/Profiles/Profile6";
import Profile4_1 from "./components/Profiles/Profile4_1";
import ProjectReview from "./components/ProjectReview";
import "react-toastify/dist/ReactToastify.css";
import ProfileSocial from "./components/Profiles/ProfileSocial";
import Profile0 from "./components/Profiles/Profile0";
import ForgotPassword from "./pages/ForgotPassword";
import AccessManagement from "./pages/AccessManagement";
import ViewResume from "./pages/ViewResume";
import axios from "axios";
import apiUrl from "./apiConfig";
import Documents from "./pages/Documents";
import OuterDashBoard from "./pages/OuterDashBoard";
import Settings from "./pages/Settings";
import AdminPage from "./pages/AdminPage";
import LandingPage from "./pages/LandingpageMain";
import Courses from "./pages/Courses";
import CourseDesign from "./pages/CourseDesign";
import NetWork from "./pages/NetWork";
import OuterJObPosting from "./pages/OuterJObPosting";
import Page1 from "./components/JobPostings/Page1";
import Page2 from "./components/JobPostings/Page2";
import Page3 from "./components/JobPostings/Page3";
import Jobs from "./pages/Jobs";
import SelectedJob from "./components/SelectedJob";
import Spinnerpage from "./components/Spinnerpage";
import ActiveChats from "./components/Chats/ActiveChats";
import ChatDetails from "./components/Chats/ChatArea";
import { WebSocketContext } from "./contexts/WebSocketContext";
import io, { Socket } from "socket.io-client";
import Chatsdefault from "./components/Chats/Chatsdefault";
import Recruiter from "./pages/Recruiter";
import CandidateSearch from "./pages/CandidateSearch";
import FilteredProfiles from "./components/Recruiter/FilteredProfiles";
import AppliedJobs from "./pages/AppliedJobs";
import EmployerLogin from "./pages/EmployerLogin";
import EmployerSignup from "./pages/EmployerSignup";
import RecruiterNavbar from "./components/Recruiter/RecruiterNavbar";

import { useForm } from "react-hook-form";
import NewPeople from "./pages/NewPeople";
import Bugreport from "./components/Bugreport";
import AccountDeletionFeedback from "./pages/AccountDeletionFeedback";
import ReqSent from "./components/NetworkComponents/ReqSent";
import Connections from "./components/NetworkComponents/Connections";
import Tags from "./components/NetworkComponents/Tags";
import OuterTag from "./components/NetworkComponents/OuterTag";
import Profile3_1 from "./components/Profiles/Profile3_1";
import AdminAddLogos from "./components/AdminAddLogos";
import GlobalAdmin from "./pages/GlobalAdmin";
import Lists from "./components/NetworkComponents/Lists";
export default function App() {
  const formdetails = {};
  const [isDarkMode, setisDarkMode] = useState(false);
  const toggleDarkMode = (isdark) => {
    setisDarkMode(isdark);
  };
  const curraddsubmit = async (data) => {
    const da = {
      address: data,
    };
    try {
      const response = await axios.post(apiUrl.url.seturl + token, da);
      if (response.status === 200) {
        setcuradd(da);
        setapi(!api);
        // // // console,log("address updated");
      }
    } catch (err) {}
  };
  const Location = useLocation();
  const navigate = useNavigate();
  const [url, seturl] = useState("");
  const [email, setemail] = useState("");
  const [curradd, setcuradd] = useState({});
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const [api, setapi] = useState(false);
  const refreshtoken = localStorage.getItem("refreshtoken");
  const [loading, setloading] = useState(false);
  const [userId, setUserId] = useState({});
  const handleBack = () => {
    navigate("/jobs");
    setSelectedJob(null);
  };

  const [selectedJob, setSelectedJob] = useState(null);
  const { socket, connectSocket, disconnectSocket } =
    useContext(WebSocketContext);

  useEffect(() => {
    const fetchurl = async () => {
      {
        setloading(true);
        try {

          const reqbody = {
            refreshtoken: refreshtoken,
            url: Location.pathname.split("/")[1],
          };
          const response = await axios.post(apiUrl.url.geturl + token, reqbody);
          if (response && response.status === 200) {
            seturl(response.data.url.url);
            if (socket) {
              if (!socket.connected) {
                connectSocket();
              }
            } else {
              connectSocket();
            }
          
            if (token && Location.pathname === "/") {
              navigate(response.data.url.url);
            }
            setemail(response.data.url.email);
            setcuradd(response.data.url.address);
            setloading(false);
          }
        } catch (err) {
        }
        setloading(false);
      }
    };

    const fetchurlwot = async () => {
      {
        setloading(true);
        try {
          const reqbody = {
            refreshtoken: refreshtoken,
            url: Location.pathname.split("/")[1],
          };
          const response = await axios.post(apiUrl.url.geturl, reqbody);
          if (response && response.status === 200) {
            setUserId(response.data.url.UserId);
            seturl(response.data.url.url);
            setcuradd(response.data.url.address);
          }
        } catch (err) {
        }
        setloading(false);
      }
    };

    if (type == "admin") {
    } else {
      if (token) {
        fetchurl();
      } else {
        fetchurlwot();
      }
    }

    return () => {
     
    };
  }, [api, Location]);

  useEffect(() => {
    if (token) {
      if (socket) {
        if (!socket.connected) {
          connectSocket();
        }
      }
    }
  }, [token]);
 

  // Jobs

  // const SelectedJobWrapper = ({ handleBack, selectedJob, setSelectedJob }) => {
  //   const location = useLocation();
  //   const params = new URLSearchParams(location.search);
  //   const jobId = params.get("jobId");

  //   useEffect(async () => {
  //     if (jobId) {
  //       // Fetch or set the selected job based on the jobId
  //       const response = await axios.post(
  //         apiUrl.jobposting.getjob + token,
  //         jobId
  //       );
  //       // // // console,log(response);
  //       setSelectedJob(response.data.job);
  //     }
  //   }, [jobId, setSelectedJob]);

  //   return (
  //     <SelectedJob
  //       handleBack={handleBack}
  //       selectedJob={selectedJob}
  //       setSelectedJob={setSelectedJob}
  //     />
  //   );
  // };

  const [refresh, setrefresh] = useState(false);



  const disconnect = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setrefresh(!refresh);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  return (
    <div
      className={` min-h-screen w-full min-w-[300px] ${
        isDarkMode ? "text-gray-100 dark-mode" : "text-black light-mode"
      } `}
      // data-theme={isDarkMode ? "dim" : "light"}
    >
      <div className="  z-999 relative bg-black">
        {Location.pathname.startsWith("/recruiter") || type == "recruiter" ? (
          <RecruiterNavbar
            isDarkMode={isDarkMode}
            setisDarkMode={setisDarkMode}
            toggleDarkMode={toggleDarkMode}
            url={url}
            api={api}
            seturl={seturl}
            setapi={setapi}
          />
        ) : (
          <Navbar
            isDarkMode={isDarkMode}
            setisDarkMode={setisDarkMode}
            toggleDarkMode={toggleDarkMode}
            url={url}
            api={api}
            seturl={seturl}
            setapi={setapi}
          />
        )}
        {/* <Navbar
          isDarkMode={isDarkMode}
          setisDarkMode={setisDarkMode}
          toggleDarkMode={toggleDarkMode}
          url={url}
          api={api}
          seturl={seturl}
          setapi={setapi}
        /> */}

        <div className="border-b border-[1.5px] border-gray-200"></div>
      </div>

      <div className="mt-11" style={{ minHeight: "calc(100vh-3rem)" }}>
        <Routes>
          <Route element={<Landing isDarkMode={isDarkMode} />} path="/" />
          <Route
            element={<Signup isDarkMode={isDarkMode} url={url} />}
            path="/signup"
          />
          <Route element={<LandingPage />} path="/learning/dsa" />
          <Route
            element={<Login isDarkMode={isDarkMode} url={url} />}
            path="/login"
          />
          <Route
            element={<ForgotPassword isDarkMode={isDarkMode} url={url} />}
            path="/forgotpassword"
          />
          <Route
            element={<AccessManagement url={url} isDarkMode={isDarkMode} />}
            path="/accessmanagement"
          />
          <Route
            element={<Documents isDarkMode={isDarkMode} url={url} />}
            path="/documents"
          />
          <Route
            element={<AdminPage isDarkMode={isDarkMode} url={url} />}
            path="/admin/:email"
          />
          <Route
            element={<AdminAddLogos isDarkMode={isDarkMode} url={url} />}
            path="/admin/addlogos"
          />
          <Route path="/globaladmin" element={<GlobalAdmin/>}/>
          <Route
            element={
              <Settings
                url={url}
                isDarkMode={isDarkMode}
                setisDarkMode={setisDarkMode}
                toggleDarkMode={toggleDarkMode}
                email={email}
              />
            }
            path="/settings"
          />

          <Route
            element={<Profileincom isDarkMode={isDarkMode} />}
            path="/signup"
          >
            <Route
              path="phoneno"
              element={<Profile0 isDarkMode={isDarkMode} />}
            />
            <Route
              path="resume"
              element={
                <Profile1 formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />

            <Route
              path="address"
              element={
                <Profile3 formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />
            <Route
              path="profilepicture"
              element={<Profile3_1 formdetails={formdetails} />}
            />
            <Route
              path="add-education"
              element={
                <Profile4 formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />
            <Route
              path="education-review"
              element={
                <Educationreview
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                />
              }
            />
            <Route
              path="add-project"
              element={
                <Profile4_1 formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />

            <Route
              path="project-review"
              element={
                <ProjectReview
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                />
              }
            />
            {/* sdfsdf */}
            <Route
              path="add-experience"
              element={
                <Profile5 formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />
            <Route
              path="job-review"
              element={
                <JobReview formdetails={formdetails} isDarkMode={isDarkMode} />
              }
            />
            <Route
              path="add-skills"
              element={
                <Profile6
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                  url={url}
                />
              }
            />

            <Route
              path="add-socialprofiles"
              element={
                <ProfileSocial
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                  url={url}
                  seturl={seturl}
                />
              }
            />
          </Route>
          <Route
            path="/deleteaccount/*"
            element={<AccountDeletionFeedback />}
          />
          <Route element={<OuterDashBoard />} path={`${url}/*`}>
            <Route
              element={
                <Dashboard
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                  curradd={curradd}
                  setcuradd={setcuradd}
                  url={url}
                  seturl={seturl}
                  api={api}
                  setapi={setapi}
                  curraddsubmit={curraddsubmit}
                />
              }
              path=""
            />
            <Route
              element={
                <ViewResume
                  formdetails={formdetails}
                  isDarkMode={isDarkMode}
                  url={url}
                />
              }
              path="resume"
            />
            <Route element={<Spinnerpage isDarkMode={isDarkMode} />} path="*" />
          </Route>
          {/* <Route
            element={<LocationMap isDarkMode={isDarkMode} />}
            path="/locationmap"
          />
          <Route
            element={<ResumeBuilder isDarkMode={isDarkMode} />}
            path="/resumebuilder"
          /> */}

          {/* Job Posting and Recruiter Routes */}
          {/* <Route element={<Recruiter />} path="recruiter" />
          <Route element={<CandidateSearch />} path="recruiter/candidatesearch">
            <Route element={<FilteredProfiles />} path="" />
          </Route>
          <Route element={<OuterJObPosting />} path="/recruiter/job-posting">
            <Route element={<Page1 isDarkMode={isDarkMode} />} path="page1" />
            <Route element={<Page2 isDarkMode={isDarkMode} />} path="page2" />
            <Route element={<Page3 isDarkMode={isDarkMode} />} path="page3" />
          </Route>
          <Route
            path="/jobs"
            element={
              <Jobs
                selectedJob={selectedJob}
                handleBack={handleBack}
                setSelectedJob={setSelectedJob}
              />
            }
          >
            <Route
              path="/jobs/:jobId"
              element={
                <Jobs
                  handleBack={handleBack}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                />
              }
            />
          </Route>
          <Route path="/employers/login" element={<EmployerLogin />} />
          <Route path="/employers/signup" element={<EmployerSignup />} />
          <Route path="/jobs/applied" element={<AppliedJobs />} /> */}
          <Route element={<ActiveChats />} path="/chats">
            <Route element={<Chatsdefault />} path="" />
            <Route
              element={<ChatDetails isDarkMode={isDarkMode} />}
              path=":chaturl"
            />
            <Route element={<Chatsdefault />} path="*" />
          </Route>
          <Route
            element={
              <NetWork
                seturl={seturl}
                refresh={refresh}
                setrefresh={setrefresh}
              />
            }
            path="/network"
          >
            <Route
              element={
                <NewPeople
                  seturl={seturl}
                  refresh={refresh}
                  setrefresh={setrefresh}
                />
              }
              path="newpeople"
            />
            <Route
              element={<ReqSent refresh={refresh} setrefresh={setrefresh} />}
              path="invitations"
            />
            <Route
              element={
                <Connections
                  disconnect={disconnect}
                  refresh={refresh}
                  setrefresh={setrefresh}
                />
              }
              path="connections"
            />
            <Route
              element={
                <OuterTag refresh={refresh} setrefresh={setrefresh}/>
              }
              path="tags"
            />
            <Route element={<Lists/>} path="lists"/>
          </Route>
          <Route element={<Courses />} path="courses">
            <Route element={<CourseDesign />} path="dsa" />
          </Route>
          <Route element={<Spinnerpage isDarkMode={isDarkMode} />} path="*" />
        </Routes>
      </div>

      {Location.pathname !== "/login" && Location.pathname !== "/signup" && (
        <Footer />
      )}

      <Bugreport />
    </div>
  );
}
