import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import AddNewListModal from "./AddNewListModal";


export default function Lists() {

    const [openAddNewListModal, setOpenAddNewListModal] = useState(false);
    const [lists, setLists] = useState([]);
    const [openDeleteListModal, setOpenDeleteListModal] = useState(false);
    const [openEditListModal, setOpenEditListModal] = useState(false);
    const [selectedList, setSelectedList] = useState({});
    const [activeList, setActivelist] = useState({});
    const [param, setParam] = useState(false);
    const [loading, setloading] = useState(false);
    const token = localStorage.getItem("token");
    const handleOpenListModal = () => {
        setOpenAddNewListModal(!openAddNewListModal);
    }

    const fetchLists = async () => {
        setloading(true);
        try {
            const response = await axios.post(apiUrl.lists.getlists + token);
            if(response.status == 200){
                console.log(response.data)
                setloading(false);
                setLists(response.data.lists.Lists);
            }
        } catch (error) {
            
        }
    }
    useEffect(() => {
        fetchLists();
    }, [param]);

    const handleEditListModal = (list) => {
        setOpenAddNewListModal(!openAddNewListModal);
    }
    const handleDeleteListModal = (list) => {
        setOpenAddNewListModal(!openAddNewListModal);
    }

  return (
    <div className="bg-white w-full border-[1px]  md:rounded-md px-4 py-2">
        <div className="flex justify-between items-center mb-4">
        <h1 className=" text-lg font-[550]">Lists</h1>
        <button
          className="duration-300 font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025] h-fit"
          onClick={handleOpenListModal}
        >
          Add List
        </button>
        </div>
        {openAddNewListModal && (
          <AddNewListModal
            handleOpenTagModal={handleOpenListModal}
            param={param}
            setParam={setParam}
            token={token}
          />
        )}
        <div className={lists.length !== 0 ? "" : "w-full"}>
          {loading ? (
            <div className="w-full py-4 flex justify-center items-center">
              <div className="flex space-x-2 w-20">
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
              </div>
            </div>
          ) : lists.length !== 0 ? (
            lists.map((list, index) => (
              <div
                className={`text-base  flex flex-col md:flex-row md:items-center  w-full ${
                  lists.length - 1 === index ? "" : "border-b-[1px]"
                } py-2 flex justify-between hover:bg-blue-100 cursor-pointer px-2`}
                key={index}
              >
                <div className=" flex justify-between ">
                  {" "}
                  <div>{list.ListName}</div>
                  <div className=" md:hidden inline-block ">
                    {" "}
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className=" duration-300   font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                        >
                          <div className="py-1">
                          <Menu.Item>
                            <div
                              className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                              onClick={() => handleEditListModal(list)}
                            >
                              Edit List
                            </div>
                          </Menu.Item>
                        </div>
                          <div className="py-1">
                            <Menu.Item>
                              <div
                                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                                onClick={() => handleDeleteListModal(list)}
                              >
                                Delete List
                              </div>
                            </Menu.Item>
                            
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>{" "}
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className=" text-[#0052ff] hover:underline cursor-pointer text-xs md:text-sm"
                    onClick={() => setActivelist(list)}
                  >
                    {list.ListUsers.length}{" "}
                    {list.ListUsers.length === 1 ? "Connection" : "Connections"}
                  </div>

                  <Menu
                    as="div"
                    className="relative md:inline-block hidden text-left"
                    onClick={() => setSelectedList(list)}
                  >
                    <div>
                      <Menu.Button className=" duration-300  pt-2 font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                         <div className="py-1">
                          <Menu.Item>
                            <div
                              className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                              onClick={() => handleEditListModal(list)}
                            >
                              Edit List
                            </div>
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            <div
                              className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                              onClick={() => handleDeleteListModal(list)}
                            >
                              Delete List
                            </div>
                          </Menu.Item>
                        </div>
                        {/* <div className="py-1">
                        <Menu.Item>
                              <div
                                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                                onClick={() => handleDeleteListModal(list)}
                              >
                                Edit List
                              </div>
                            </Menu.Item>
                        </div> */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            ))
          ) : (
            <div className=" text-base font-[550] text-center w-full  pb-2">
              No Lists
            </div>
          )}
        </div>
      </div>
  );
}
